import axios from "axios";
import querystring from 'querystring';
import { LOGIN_BASE_URL, CLIENT_ID, CLIENT_CREDENTIALS } from '../constants';
import { saveLoginInfo, getRefreshToken } from '../core/security/auth.jsx';

let API_BASE_URL = LOGIN_BASE_URL;

export function login(email, password) {
    return axios.post(`${API_BASE_URL}/login`, 
        {
            'email': email,
            'password': password
        }).then(res => {
            saveLoginInfo({access_token: res.data})
            return res
        });
}

async function getSession({ access_token }) {
    const res = await axios.get(`${API_BASE_URL}/session`, {headers: {'Authorization': `Bearer ${access_token}`}});
    return res.data;
}

export async function refreshToken() {
    const res = await axios.post(`${API_BASE_URL}/login`, 
        querystring.stringify({
            refresh_token: getRefreshToken(),
            grant_type: 'refresh_token',
            client_id: CLIENT_ID
        }), {
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            auth: {
                username: CLIENT_ID,
                password: CLIENT_CREDENTIALS
            },
    });
    if (!res.data.error) {
        const me = await getSession(res.data);
        saveLoginInfo(res.data, me)
    }
    return res.data;
}