import React, {Component} from 'react';
import '../login/Login.scss';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
// import { UserServices } from '../constituent-management/contact/UserServices';
import ReCAPTCHA from "react-google-recaptcha";
// import { ConfigurationServices } from '../tenant-configuration/ConfigruationServices'


export default class RecoverUsername extends Component {
    constructor() {
        super();
        this.state = {
           email: '',
           mailTemplateRecoverUsername: 0
        }

        // this.userServices = new UserServices();
        // this.configurationServices = new ConfigurationServices();
    }

    handleEmailEnter = (e) => {
        if (e.key === 'Enter'){
        }
    }

    handleRecoverUsername = () => {
        if(this.state.captchaFlag === false){
            alert("Captcha is required !");
        }else {
            
        }
    }

    getMailTemplate = () => {
        // this.configurationServices.getMailTemplate()
        // .then(res => {
        //     this.setState({
        //         mailTemplateRecoverUsername: res.systemConfig.mailTemplateRecoverUsername ? res.systemConfig.mailTemplateRecoverUsername : 0
        //     });
        // })
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="login-body">
                 <div className="login-panel p-fluid">
                    <div className="login-panel-header">
                        <img className="logo" src="assets/fromlabs/logo.png" alt="Logo"/>
                    </div>
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h1>Recover Username</h1>
                            </div>
                            <div className="p-col-12 p-margin-bottom-20">
                                <label>Email Address</label>
                                <InputText value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} onKeyPress={this.handleEmailEnter}/>   
                            </div>
                            <div className="p-margin-left-10">
                            <ReCAPTCHA
                                sitekey="6LeTGcoUAAAAAI4N9_s7t_WGyOyocpOcT-OzSy0B"
                                onChange={(e) => this.setState({captchaFlag : true})}
                            />
                            </div>
                            <div className="p-col-12 p-margin-top-15">
                                <Button label="Submit" onClick={this.handleRecoverUsername}/>
                            </div>
                            <div className="p-col-12 ">
                                <Button label="Login" onClick={() => this.props.history.goBack()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}