import axios from 'axios';
import { CONFIGURATION_BASE_URL } from '../constants';

export const loadPublicConfiguration = async () => {
    const configs = await axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/configs/public/restaurant`);
    localStorage.setItem('resConfig', JSON.stringify(configs.data));

    configs.data.defaultLanguage && localStorage.setItem('defaultLocale', configs.data.defaultLanguage);
    configs.data.enabledLanguages && localStorage.setItem('enabledLocales', configs.data.enabledLanguages);
    configs.data.resApplicationUrl && localStorage.setItem('resApplicationUrl', configs.data.resApplicationUrl);

    configs.data.currency && localStorage.setItem('currency', configs.data.currency);
    configs.data.currencySymbol && localStorage.setItem('currencySymbol', configs.data.currencySymbol);
    configs.data.currencySign && localStorage.setItem('currencySign', configs.data.currencySign);

    localStorage.setItem('defaultTax', configs.data.defaultTax);
    localStorage.setItem('isPriceIncludedTax', configs.data.showPriceIncludedTax);
    localStorage.setItem('takeAwayTableCode', configs.data.takeAwayTable);
    localStorage.setItem('countryCode', configs.countryCode);

    return configs;
}

export function getConfig(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/restaurant`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveRestaurantConfig(data, type){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/restaurant/${type}`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function getTimezones(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/timezone/list`)
    .then(res => res.data).catch(error => console.log(error));
}
