import axios from 'axios';
import { CONFIGURATION_BASE_URL } from "../constants";

export function getCountries(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/countries/list`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getStatesByCountry(code){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/countries/${code}/states`)
    .then(res => res.data).catch(error => console.log(error));
}