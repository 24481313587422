import React, { Component } from 'react';
// import { isAuthenticated } from '../core/security/auth.jsx';
// import { login as requestLogin } from "./AuthServices.jsx";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { withNamespaces, Trans } from 'react-i18next';
import '../login/Login.scss';
import { loadPublicConfiguration, getTimezones } from '../service/ConfigurationService.js';
import { Message } from 'primereact/message';
import { Redirect } from 'react-router-dom';
import './RestaurantForm.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import { subcribe } from './RestaurantFormService';
import * as notify from '../core/service/NotificationService';
import { parseMessage } from '../core/utils/TranslationUtils';
import { getCountries } from '../country/CountrySevices';

const languages = [
    { label: 'English - United States', value: 'en-US' },
    { label: 'English - United Kingdom', value: 'en-UK' },
    { label: 'French - France', value: 'fr-FR' },
    { label: 'Finn - Finnish', value: 'fi' },
    { label: 'German - Germany', value: 'de' },
    { label: 'Swedish - Sweden', value: 'sv-SE' },
    { label: 'Vietnamese - Vietnam', value: 'vi' },
    { label: 'Spanish - Spain', value: 'es' },
    { label: 'Bahasa - Indonesia', value: 'in' },
    { label: 'Portuguese - Portugal', value: 'pt-PT' },
    { label: 'Portuguese - Brazil', value: 'pt-BR' },
    { label: 'Chinese (Simplified) - China', value: 'zh-CN' },
    { label: 'Japanese - Japan', value: 'ja' },
    { label: 'Korean - Korea', value: 'ko' },
    { label: 'Dutch - Netherland', value: 'nl' },
    { label: 'Norwegian - Norway', value: 'no' },
    { label: 'Turkish - Turkey', value: 'tr' },
    { label: 'Chinese (Traditional) - Taiwan', value: 'zh-TW' },
    { label: 'Danish - Denmark', value: 'da' },
];

class RestaurantForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appLogo: 'assets/fromlabs/origin-logo-2.png',
            username: '',
            password: '',
            language: {},
            isError: false,
            data: {
                resName: '',
                resWebsite: '',
                ownerFullName: '',
                ownerEmail: '',
                addressLine1: '',
                city: '',
                country: '',
                phone: '',
                mainLang: ''
            },
            timezones: [],
            errors: {},
            requestStatus: 'new',
        };
        // this.textInput = React.createRef();
    }

    componentDidMount() {
        this.loadTimezones();
        const { i18n } = this.props;
        // i18n.changeLanguage(localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale'));
        i18n.changeLanguage('en');
        this.loadTimezones();
    }

    loadTimezones = () => {
        getTimezones().then(res => {
            this.setState({ timezones: res ? res : [] })
        })
    }

    loadCountries = () => {
        getCountries().then(res => {
            this.setState({ countries: res?res:[] })
        })
    }

    handleSubcribe = () => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        console.log(this.state.data);
        subcribe(this.state.data)
            .then(res => {
                if (!res) {
                    this.setState({
                        data: {
                            resName: '',
                            resWebsite: '',
                            ownerFullName: '',
                            ownerEmail: '',
                            addressLine1: '',
                            city: '',
                            country: '',
                            phone: '',
                            mainLang: ''
                        },
                        errors: {},
                        requestStatus: 'completed'
                    });
                    notify.showSuccessNotify(trans('res.mgt.success_desc'))
                } else {
                    // notify.showErrorNotify(trans('res.mgt.errors.bad_request'))
                    if(res.errorCode === 400) this.setState({errors: res.errorObj})
                }
            });
    }

    onChangeDefaultLanguage = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                mainLang: e.value,
            }
        });
    }

    onChangeCountry = (e) => {
        this.setState({
            data: {...this.state.data, country: e.value},
        });
    }

    render() {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        const asterik = <span style={{ color: "red" }}>*</span>

        return (
            <div className="login-body">
                <div className="p-grid form-container p-justify-center">
                    <div className="p-col-10 p-md-6 p-sm-10 p-lg-4">
                        <div className="card card-w-title form-card">
                            <div className="login-panel-header fl-login-header">
                                <img className="logo" src={this.state.appLogo} alt="Application Logo" />
                                <h2>{trans('res.mgt.restaurant_account_request')}</h2>
                            </div>
                            {this.state.requestStatus === 'new' &&
                                <div className="p-grid p-fluid p-justify-center" style={{ marginTop: "10px" }}>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.restaurant_name')}</label>
                                        <InputText value={this.state.data.resName} onChange={(e) => this.setState({ data: { ...this.state.data, resName: e.target.value } })} />
                                        <div className="p-form-error">
                                            {this.state.errors.resName && <div>{parseMessage(trans, this.state.errors.resName.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{trans('res.mgt.restaurant_website')}</label>
                                        <InputText value={this.state.data.resWebsite} onChange={(e) => this.setState({ data: { ...this.state.data, resWebsite: e.target.value } })} />
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.person-in-charge')}</label>
                                        <InputText value={this.state.data.ownerFullName} onChange={(e) => this.setState({ data: { ...this.state.data, ownerFullName: e.target.value } })} />
                                        <div className="p-form-error">
                                            {this.state.errors.ownerFullName && <div>{parseMessage(trans, this.state.errors.ownerFullName.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.owner_email')}</label>
                                        <InputText value={this.state.data.ownerEmail} keyfilter="email" onChange={(e) => this.setState({ data: { ...this.state.data, ownerEmail: e.target.value } })} />
                                        <div className="p-form-error">
                                            {this.state.errors.ownerEmail && <div>{parseMessage(trans, this.state.errors.ownerEmail.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.address')}</label>
                                        <InputText value={this.state.data.addressLine1} onChange={(e) => this.setState({ data: { ...this.state.data, address: e.target.value } })} />
                                        <div className="p-form-error">
                                            {this.state.errors.address && <div>{parseMessage(trans, this.state.errors.addresss.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.city')}</label>
                                        <InputText value={this.state.data.city} onChange={(e) => this.setState({ data: { ...this.state.data, city: e.target.value } })} />
                                        <div className="p-form-error">
                                            {this.state.errors.city && <div>{parseMessage(trans, this.state.errors.city.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.country')}</label>
                                        <InputText value={this.state.data.country} onChange={(e) => this.setState({ data: { ...this.state.data, country: e.target.value } })} />
                                        {/* <Dropdown value={this.state.data.country} options={this.state.countries} onChange={(e) => this.onChangeCountry(e)} /> */}
                                        <div className="p-form-error">
                                            {this.state.errors.country && <div>{parseMessage(trans, this.state.errors.country.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.phone')}</label>
                                        <InputText value={this.state.data.phone} onChange={(e) => this.setState({ data: { ...this.state.data, phone: e.target.value } })} />
                                        <div className="p-form-error">
                                            {this.state.errors.phone && <div>{parseMessage(trans, this.state.errors.phone.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">{asterik} {trans('res.mgt.main_language')}</label>
                                        <Dropdown value={this.state.data.mainLang} options={languages} onChange={(e) => this.onChangeDefaultLanguage(e)} />
                                        <div className="p-form-error">
                                            {this.state.errors.mainLang && <div>{parseMessage(trans, this.state.errors.mainLang.code)}</div>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <Button className="btn-subcribe" label={trans('res.mgt.submit')} onClick={() => this.handleSubcribe()} />
                                    </div>
                                </div>
                            }
                            {this.state.requestStatus === 'completed' &&
                                <div className="p-grid p-fluid p-justify-center" style={{ marginTop: "10px" }}>
                                    {trans('res.mgt.request-submited-message')}
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="login-background-image">
                    <img alt="background" src="/assets/fromlabs/Restaurant-background.png" />
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(RestaurantForm);