import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import lazyLoad from './core/components/lazyLoad';

import AuthRoute from './core/security/AuthRoute.jsx';
// import CustomerOrder from './CustomerOrder';
import ResetPassword from './reset-password/ResetPassword.js';
import RecoverUsername from './reset-password/RecoverUsername.js';
import ResetPasswordLink from './reset-password/ResetPasswordLink.js';
// import CustomerInform from './CustomerInform';
// import TakeAwayOrder from './take-away/TakeAwayOrder';
import { USE_FOR_PURPOSE } from './constants';
import RestaurantForm from './inquery-form/RestaurantForm';
// import StandAloneMenu from './stand-alone-menu/StandAloneMenu';

const Login = lazyLoad(() => import('./login/Login'));
const Subscription = lazyLoad(() => import('./Subscription'));

export const Main = () => {
  return (
    <Switch>
      <Redirect exact path="/" to="/restaurant-form"/>
      
      <Route path="/login" component={Login}/>
      <Route path='/mgt' component={Subscription} />
      <Route path="/restaurant-form" component={RestaurantForm}/>
    </Switch>
  );
}
